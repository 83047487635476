import {isEqualsNotEmptyString} from "../utils/utils";

export function checkFields(): boolean {
    const sendButton: any = document.getElementById('submit_button');
    const inputs: any = document.getElementsByTagName('input');
    const phoneContainer: any = document.getElementById('tel_input_container');
    const EMAIL_REGEXP = /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/iu;
    if (!sendButton || !inputs || inputs.length === 0) {
        return false;
    }
    const currentYear = new Date().getFullYear();
    function check() {
        const phone = inputs.phone.value;
        const email = inputs.email.value;
        const birthdate = inputs.birthdate.value;
        const intPassportDate = inputs.int_passport_date.value;
        const year = birthdate && birthdate.toString().length > 9 ? Number(birthdate.toString().substring(0, birthdate.toString().indexOf('-'))) : undefined;
        const intYear = intPassportDate && intPassportDate.toString().length > 9 ? Number(intPassportDate.toString().substring(0, intPassportDate.toString().indexOf('-'))) : undefined;
        if (phone && phone.length === 13 && email && email.length > 3 && EMAIL_REGEXP.test(email)
            && year && year > 1900 && year < currentYear - 14
            && intYear && intYear > 1900 && intYear <= currentYear) {
            sendButton.classList.remove('disabled');
        } else {
            sendButton.classList.add('disabled');
        }
    }

    function addErrorToFields() {
        const year = Number(inputs.birthdate.value.toString().substring(0, inputs.birthdate.value.indexOf('-')));
        const intPassportYear = Number(inputs.int_passport_date.value.toString().substring(0, inputs.int_passport_date.value.indexOf('-')));
        if (!inputs.phone.value || inputs.phone.value.length < 13) {
            phoneContainer.classList.add('error');
            window.scrollTo({top: document.body.scrollHeight, behavior: 'smooth'});
        } else if (phoneContainer.classList.contains('error')) {
            phoneContainer.classList.remove('error');
        }
        if (!inputs.email.value || inputs.email.value.length < 3 || !EMAIL_REGEXP.test(inputs.email.value)) {
            inputs.email.classList.add('error');
        } else {
            inputs.email.classList.remove('error');
        }
        if (inputs.birthdate.value && inputs.birthdate.value.length > 9) {
            if (year >= currentYear - 14 || year <= 1900) {
                inputs.birthdate.classList.add('error');
                const dateErrorLabel = document.getElementById('datepicker_error');
                if (dateErrorLabel) {
                    dateErrorLabel.style.display = 'block';
                }
                window.scrollTo({top: 0, behavior: 'smooth'});
            }
        }
        if (inputs.int_passport_date.value && inputs.int_passport_date.value.length > 9) {
            if (intPassportYear > currentYear || intPassportYear <= 1900) {
                inputs.int_passport_date.classList.add('error');
                const dateErrorLabel = document.getElementById('int_passport_date_error');
                if (dateErrorLabel) {
                    dateErrorLabel.style.display = 'block';
                }
            }
        }
        const codewordValue = inputs.codeword.value.toLowerCase();
        if (isEqualsNotEmptyString(codewordValue, inputs.first_name.value) || isEqualsNotEmptyString(codewordValue, inputs.middle_name.value)
            || isEqualsNotEmptyString(codewordValue, inputs.last_name.value)) {
            inputs.codeword.reportValidity();
            inputs.codeword.setCustomValidity('Не должно содержать ФИО');
        } else {
            inputs.codeword.setCustomValidity('');
        }
    }

    inputs.phone.addEventListener('input', function (e: any) {
        if (!inputs.phone.value && !sendButton.classList.contains('disabled')) {
            sendButton.classList.add('disabled');
        }
        const value = e.target.value.replace(/[^\d]/g, '').substring(0, 13);
        if (value && value.length > 9 && phoneContainer.classList.contains('error')) {
            phoneContainer.classList.remove('error');
        }
        check();
    });
    inputs.email.addEventListener('input', function (e: any) {
        const value = e.target.value;
        if (!value || value.length < 3 || !EMAIL_REGEXP.test(value)) {
            inputs.email.classList.add('error');
            if (!sendButton.classList.contains('disabled')) {
                sendButton.classList.add('disabled');
            }
        } else {
            inputs.email.classList.remove('error');
        }
        check();
    });
    const birthdateInput = inputs.birthdate;
    birthdateInput.addEventListener('input', function (e: any) {
        const labelError = document.getElementById('datepicker_error');
        const year = Number(e.target.value.toString().substring(0, e.target.value.indexOf('-')));
        if (!year) {
            return;
        }
        if (year >= currentYear - 14 || year <= 1900) {
            if (labelError) {
                labelError.style.display = 'block';
            }
            if (!birthdateInput.classList.contains('error')) {
                birthdateInput.classList.add('error');
            }
        } else if (birthdateInput.classList.contains('error')) {
            if (labelError) {
                labelError.style.display = 'none';
            }
            birthdateInput.classList.remove('error');
        }
        check();
    });
    const intBirthdateInput = inputs.int_passport_date;
    intBirthdateInput.addEventListener('input', function (e: any) {
        const labelError = document.getElementById('int_passport_date_error');
        const year = Number(e.target.value.toString().substring(0, e.target.value.indexOf('-')));
        if (!year) {
            return;
        }
        if (year > currentYear || year <= 1900) {
            if (labelError) {
                labelError.style.display = 'block';
            }
            if (!intBirthdateInput.classList.contains('error')) {
                intBirthdateInput.classList.add('error');
            }
        } else if (intBirthdateInput.classList.contains('error')) {
            if (labelError) {
                labelError.style.display = 'none';
            }
            intBirthdateInput.classList.remove('error');
        }
        check();
    });
    sendButton.addEventListener('click', function (e: any) {
        const phone = inputs.phone.value;
        const email = inputs.email.value;
        const year = Number(inputs.birthdate.value.toString().substring(0, inputs.birthdate.value.indexOf('-')));
        const intPassportYear = Number(inputs.int_passport_date.value.toString().substring(0, inputs.int_passport_date.value.indexOf('-')));
        if (phone && phone.length === 13 && email && email.length > 3 && EMAIL_REGEXP.test(email)
            && year && year < currentYear - 14 && year > 1900
            && intPassportYear && intPassportYear <= currentYear && year > 1900) {
            sendButton.classList.remove('disabled');
            return;
        }
        e.preventDefault();
        addErrorToFields();
    });

    const phone = inputs.phone.value;
    const email = inputs.email.value;
    const year = Number(inputs.birthdate.value.toString().substring(0, inputs.birthdate.value.indexOf('-')));
    const intPassportYear = Number(inputs.int_passport_date.value.toString().substring(0, inputs.int_passport_date.value.indexOf('-')));
    check();
    addErrorToFields();
    return !!(phone && phone.length === 13 && email && email.length > 3 && EMAIL_REGEXP.test(email)
        && year && year < currentYear - 14 && year > 1900
        && intPassportYear && intPassportYear <= currentYear && year > 1900);
}
