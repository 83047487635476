export function autoFocusDatepicker() {
    const birthdateInput = document.getElementById('birthdate');
    const pseudoBirthdateInput = document.getElementById('pseudo_birthdate');
    if (!birthdateInput || !pseudoBirthdateInput) {
        return;
    }

    birthdateInput.addEventListener('input', function () {
        const value = birthdateInput.value;
        if (!value || value.length === 0) {
            pseudoBirthdateInput.style.display = 'block';
        } else {
            pseudoBirthdateInput.style.display = 'none';
        }
    });
    birthdateInput.addEventListener('blur', function () {
        const value = birthdateInput.value;
        if (!value || value.length === 0) {
            pseudoBirthdateInput.style.display = 'block';
        }
    });
    birthdateInput.addEventListener('focus', function () {
        pseudoBirthdateInput.style.display = 'none';
        birthdateInput.focus();
    });
    pseudoBirthdateInput.addEventListener('focus', function () {
        pseudoBirthdateInput.style.display = 'none';
        birthdateInput.focus();
    });

    const intPassportInput = document.getElementById('int_passport_date');
    const pseudointPassportInput = document.getElementById('pseudo_int_passport_date');

    intPassportInput.addEventListener('input', function () {
        const value = intPassportInput.value;
        if (!value || value.length === 0) {
            pseudointPassportInput.style.display = 'block';
        } else {
            pseudointPassportInput.style.display = 'none';
        }
    });
    intPassportInput.addEventListener('blur', function () {
        const value = intPassportInput.value;
        if (!value || value.length === 0) {
            pseudointPassportInput.style.display = 'block';
        }
    });
    intPassportInput.addEventListener('focus', function () {
        pseudointPassportInput.style.display = 'none';
        intPassportInput.focus();
    });
    pseudointPassportInput.addEventListener('focus', function () {
        pseudointPassportInput.style.display = 'none';
        intPassportInput.focus();
    });
}
