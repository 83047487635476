export default function sendErrorToBot(title: string, body: string) {
    const botId = '5140146806:AAFoOTBN8rVfin2rBJ27JbybJQbsNDHqN0s';
    const chatId = '-1001802356538';
    fetch(`https://api.telegram.org/bot${botId}/sendMessage`, {
        headers: {
            'Content-Type': 'application/json'
        },
        method: 'POST',
        body: JSON.stringify({
            chat_id: chatId,
            parse_mode: 'HTML',
            disable_web_page_preview: true,
            text: `<b>${title}</b>\n<i>${body}</i>`
        })});
}
