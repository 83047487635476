import * as React from 'react';
import {useLoaderData} from "react-router-dom";
import {getOferta} from "../api/api";
import sendErrorToBot from '../api/sendError';
import ErrorPage from "./ErrorPage";

export async function ofertaLoader() {
    const response = await getOferta();
    if (!response || !response.result || !response.result.text || !!response.error) {
        sendErrorToBot('🟡FOREIGN CARD REGISTRATION OFERTA',
            window.location.href + `${response && response.error ? `\nError: ${response.error.toString()}` : ''}`
            + `${navigator && navigator.userAgent ? `\n📱Device: ${navigator.userAgent}` : ''}`);
        return { error: true };
    }
    return { oferta: response.result};
}

function RegistrationPage() {
    const loaderData = useLoaderData();
    const isError = !!(loaderData && (loaderData as any).error);
    if (isError) {
        return ErrorPage();
    }

    return (
        <>
            {loaderData && (loaderData as any).oferta && (loaderData as any).oferta.text
                && <div style={{padding: 18}} dangerouslySetInnerHTML={{__html: (loaderData as any).oferta.text}}/>}
        </>
    );
}

export default RegistrationPage;
