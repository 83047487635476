import * as React from 'react';
import './../styles/ErrorPage.css';

export default function ErrorPage(error?: string) {
    return(
        <div className='error_container'>
            <h1>
                {error || 'Упс… что-то пошло не так. Пожалуйста, попробуйте оформить заявку еще раз'}
            </h1>
        </div>
    );
}
